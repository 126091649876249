
.profile-teachers-container {
    background-color: #fdfdfd;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    padding: 2.5rem;
    border-radius: 10px;
    max-width: 800px;
    margin: 2rem auto;
    width: 90%;
    font-family: 'Arial', sans-serif;
    color: #444;
    transition: all 0.3s ease;
}


.logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.logo-image {
    width: 90px;
    height: 90px;
    transition: transform 0.3s;
}

.logo-image:hover {
    transform: scale(1.05);
}


.profile-title {
    font-size: 1.9rem;
    font-weight: 700;
    color: #d32f2f;
    text-align: center;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 0.07em;
}


.divider {
    border-top: 2px solid #d32f2f;
    margin-bottom: 2rem;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}


.details-section {
    display: flex;

    flex-direction: column;
    align-items: center;
    gap: 9rem;
    margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
    .details-section {
        flex-direction: row;
        align-items: flex-start;
    }
}


.teacher-logo {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s;
    margin-bottom: 1rem;
}

.teacher-logo:hover {
    transform: scale(1.05);
}


.teacher-logo-icon {
    font-size: 100px;
    color: #999;
}

.teacher-logo-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.details-section p {
    font-size: 1rem;
    color: #555;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.8rem;
    line-height: 1.6;
}

.email-link, .phone {
    color: #1a73e8;
    text-decoration: none;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.icon {
    color: #888;
}


.section-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #d32f2f;
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}


.document-list, .training-list, .subject-list {
    list-style: none;
    padding-left: 0;
}

.document-item, .training-item, .subject-item {
    display: flex;
    align-items: center;
    font-size: 0.95rem;
    color: #555;
    margin-bottom: 0.6rem;
    padding-left: 1rem;
    position: relative;
}

.document-item::before, .training-item::before, .subject-item::before {
    content: "•";
    color: #d32f2f;
    font-weight: bold;
    position: absolute;
    left: 0;
}


.document-item:hover, .training-item:hover, .subject-item:hover {
    color: #333;
}

/* Media queries for responsiveness */
@media (min-width: 768px) {
    .profile-teachers-container {
        max-width: 80%;
    }

    .teacher-logo {
        width: 150px;
        height: 150px;
    }
}

@media (max-width: 768px) {
    .profile-teachers-container {
        padding: 1.5rem;
    }

    .teacher-logo {
        width: 100px;
        height: 100px;
    }

    .profile-title {
        font-size: 1.6rem;
    }
}
