
.exam-teachers-container {
    padding: 1.5rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    /* max-width: 600px; */
    margin: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  

  .notifications-section {
    margin-bottom: 2rem;
  }
  
  .notifications-section h2 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .notifications-list {
    list-style: none;
    padding: 0;
  }
  
  .notifications-list li {
    background: #e9ecef;
    padding: 0.8rem;
    margin-bottom: 0.5rem;
    border-radius: 4px;
  }
  
 
  .pdf-submission-section {
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  }
  
  .pdf-submission-section h2 {
    font-size: 1.6rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .pdf-submission-section label {
    display: block;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .pdf-submission-section input[type="file"] {
    margin-top: 0.5rem;
    font-size: 1rem;
  }
  
  .submit-button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .exam-teachers-container {
      padding: 1rem;
    }
  
    .notifications-section h2, .pdf-submission-section h2 {
      font-size: 1.5rem;
    }
  
    .submit-button {
      font-size: 0.9rem;
      padding: 0.4rem 0.8rem;
    }
  }
  
  @media (max-width: 480px) {
    .exam-teachers-container {
      padding: 0.75rem;
    }
  
    .notifications-section h2, .pdf-submission-section h2 {
      font-size: 1.3rem;
    }
  
    .notifications-list li {
      font-size: 0.9rem;
    }
  
    .submit-button {
      font-size: 0.8rem;
      padding: 0.3rem 0.6rem;
    }
  }
  