@tailwind base;
@tailwind components;
@tailwind utilities;

/* 2. Global ------------------------------------------*/
Link:hover,
Link:focus,
Link:active {
  text-decoration: none;
  outline: none;
  transition: all 300ms ease 0s;
  -moz-transition: all 300ms ease 0s;
  -webkit-transition: all 300ms ease 0s;
  -o-transition: all 300ms ease 0s;
  -ms-transition: all 300ms ease 0s;
}

input,
select,
textarea {
  outline: none;
  appearance: unset !important;
  -moz-appearance: unset !important;
  -webkit-appearance: unset !important;
  -o-appearance: unset !important;
  -ms-appearance: unset !important;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -o-box-shadow: none !important;
  -ms-box-shadow: none !important;
}

input[type="checkbox"] {
  appearance: checkbox !important;
  -moz-appearance: checkbox !important;
  -webkit-appearance: checkbox !important;
  -o-appearance: checkbox !important;
  -ms-appearance: checkbox !important;
}

input[type="radio"] {
  appearance: radio !important;
  -moz-appearance: radio !important;
  -webkit-appearance: radio !important;
  -o-appearance: radio !important;
  -ms-appearance: radio !important;
}

img {
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0;
}

/* p {
  margin-bottom: 0px;
  line-height: 1.56;
  font-size: 16px;
} */

h1,
h4,
h5,
h6 {
  line-height: 1.2;
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-family: "Roboto Slab", serif;
  font-weight: bold;
  color: #333;
}

.form-control:focus {
  box-shadow: none;
}

/* .widget p {
  margin: 0;
} */

.main {
  display: inline-block;
  width: 100%;
}

.fix-container {
  width: 900px;
  margin: auto;
}

.fw {
  float: left;
  width: 100%;
}

.stick {
  position: fixed;
  z-index: 999;
  background: rgba(225, 225, 225, 0.8);
}

.clear {
  clear: both;
}

body {
  font-size: 14px !important;
  line-height: 1.71 !important;
  color: #666 !important;
  background: #fff !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: normal !important;
}

.display-flex {
  justify-content: space-between;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
}

.display-flex-center {
  justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
}

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

#back-to-top {
  position: fixed;
  text-align: center;
  right: 30px;
  bottom: 30px;
  color: #fff;
  cursor: pointer;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  z-index: 10000;
  height: 40px;
  width: 40px;
  line-height: 45px;
  background: rgba(0, 0, 0, 0.4) no-repeat center;
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}
#back-to-top .fa {
  font-size: 25px;
  font-weight: 700;
}

#back-to-top:hover {
  background: black no-repeat center;
}

/* .socials ul li {
  float: left;
  margin-right: 10px;
}
.socials ul li:last-child {
  margin-right: 0px;
}
.socials ul li a .fab {
  width: 45px;
  height: 45px;
  background: #fff;
  font-size: 16px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
}
.socials ul li a .fa-facebook-f {
  color: #4267b2;
}
.socials ul li a .fa-google-plus-g {
  color: #dd5144;
}
.socials ul li a .fa-twitter {
  color: #1da1f2;
}
.socials ul li a .fa-dribbble {
  color: #ea4c89;
} */

.slide-title {
  font-family: "Roboto Slab";
  text-transform: uppercase;
}

.slide-btn {
  background: #ffa037;
  display: inline-block;
  border: 2px solid transparent;
  color: #fff;
}
.slide-btn:hover {
  background-color: #ff8804;
  color: #fff;
}

.slide-desc {
  font-weight: 300;
}

.title-regular-25 {
  font-size: 25px;
}

.title a {
  color: #333;
}
.title a:hover {
  color: #ffa037;
}

.readmore {
  font-size: 15px;
  position: relative;
}
.readmore:after,
.readmore:before {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background: #ffa037;
  content: "";
  opacity: 0;
  transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -webkit-transform: translateY(-20px);
  -o-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: opacity 0.3s, -moz-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}
.readmore:before {
  top: -5px;
  transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -webkit-transform: translateY(-20px);
  -o-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
}
.readmore:after {
  bottom: -5px;
  transform: translateY(20px);
  -moz-transform: translateY(20px);
  -webkit-transform: translateY(20px);
  -o-transform: translateY(20px);
  -ms-transform: translateY(20px);
}
.readmore:hover {
  color: #ffa037;
}
.readmore:hover:after,
.readmore:hover:before {
  opacity: 1;
  transform: translateY(0px);
  -moz-transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -o-transform: translateY(0px);
  -ms-transform: translateY(0px);
}
.readmore i {
  padding-left: 7px;
}

.section-padding-large {
  padding-top: 80px;
  padding-bottom: 100px;
}

/* .section-title h2 {
  font-size: bold;
  font-family: serif;
  color: #333;
  position: relative;
  padding-bottom: 20px;
} */
/* .section-title h2:after {
  position: absolute;
  content: "";
  width: 60px;
  height: 3px;
  background: #ffa037;
  bottom: 0;
} */

/* .section-title-left h2:after {
  left: 0;
} */

.section-title-center {
  text-align: center;
  margin-bottom: 50px;
}
/* .section-title-center h2:after {
  left: 50%;
  transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
} */

.section-title-m50 {
  margin-bottom: 50px;
}

.section-title-m60 {
  margin-bottom: 60px;
}

.item-thumb {
  position: relative;
}
.item-thumb .overlay {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 4;
  transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
}
.item-thumb:hover .overlay {
  opacity: 1;
}

.background-grey {
  background: #f2f2f2;
}

.icon-play {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border: 2px solid #fff;
}
.icon-play i {
  color: #ffa037;
  font-size: 20px;
  margin-left: 5px;
}

.post-date {
  position: absolute;
  background: #ffa037;
  color: #fff;
}
.post-date span {
  display: block;
  padding: 0 14px;
}
.post-date span:first-child {
  font-size: 35px;
  font-weight: bold;
  margin-top: -5px;
  margin-bottom: -13px;
  font-family: "Roboto Slab";
}
.post-date span:last-child {
  font-size: 16px;
  margin-bottom: 6px;
}

/*Preloader*/
.images-preloader {
  position: fixed;
  z-index: 100001;
  background-color: #eee;
  width: 100%;
  height: 100%;
}

.rectangle-bounce {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -10px;
}

#preloader_1 {
  position: relative;
}

#preloader_1 span {
  display: block;
  bottom: 0px;
  width: 9px;
  height: 5px;
  background: #ffae4e;
  position: absolute;
  animation: preloader_1 1.5s infinite ease-in-out;
}

#preloader_1 span:nth-child(2) {
  left: 11px;
  animation-delay: 0.2s;
}

#preloader_1 span:nth-child(3) {
  left: 22px;
  animation-delay: 0.4s;
}

#preloader_1 span:nth-child(4) {
  left: 33px;
  animation-delay: 0.6s;
}

#preloader_1 span:nth-child(5) {
  left: 44px;
  animation-delay: 0.8s;
}

@keyframes preloader_1 {
  0% {
    height: 5px;
    transform: translateY(0px);
    background: #ffae4e;
  }
  25% {
    height: 30px;
    transform: translateY(15px);
    background: #ffae4e;
  }
  50% {
    height: 5px;
    transform: translateY(0px);
    background: #ffae4e;
  }
  100% {
    height: 5px;
    transform: translateY(0px);
    background: #ffae4e;
  }
}
.au-btn {
  background: #ffa037;
  color: #fff;
  font-size: 16px;
  border: 2px solid transparent;
  display: inline-block;
}
.au-btn:hover {
  background-color: #ff8804;
  color: #fff;
}

.au-btn-large {
  padding: 10px 45px;
}

.au-btn-small {
  padding: 10px 26px;
}

.au-btn-hover-yellow {
  position: relative;
}
.au-btn-hover-yellow:after {
  content: "";
  position: absolute;
  z-index: -1;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background: #ffa037;
}
.au-btn-hover-yellow:hover:after {
  width: 100%;
}

.heading-page {
  position: relative;
}
.heading-page .container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.au-page-title {
  margin-bottom: 18px;
}
.au-page-title h1 {
  font-size: 40px;
  color: #fff;
  position: relative;
  padding-bottom: 20px;
  text-transform: uppercase;
}
.au-page-title h1:after {
  position: absolute;
  content: "";
  width: 50px;
  height: 2px;
  background: #ffa037;
  bottom: 0;
  left: 0;
}

.widget-title {
  margin-bottom: 35px;
}
/* .widget-title h2 {
  font-size: 20px;
  color: #333;
  text-transform: uppercase;
} */

.single-title h1 {
  font-size: 30px;
  color: #333;
}

.related-title {
  font-size: 25px;
  color: #333;
}

.slick-slider .slick-slide:focus {
  outline: none;
}

.entry {
  padding: 10px 0;
  margin: 7px 0 10px 0;
  font-size: 15px;
}
.entry span {
  margin-right: 20px;
}
.entry span i {
  margin-right: 10px;
  color: #ffa037;
  font-size: 13px;
}
.entry span a {
  color: #666;
}

/* 22. Responsive ------------------------------------------*/
@media screen and (max-width: 1200px) {
  .container {
    width: 100%;
    max-width: 100%;
  }

  .sign-up-content .sign-up-form {
    margin-left: 0px;
  }

  .testimonials-content .slick-dots {
    bottom: 16px;
  }

  .slide-form {
    padding: 10px 0;
  }

  .slide-form form .form-group:first-child,
  .slide-form form .form-group:nth-child(2),
  .slide-form form .form-group:nth-child(3),
  .slide-form form .form-group:nth-child(4) {
    width: 50%;
  }

  .slide-form form .form-group:first-child,
  .slide-form form .form-group:nth-child(2),
  .slide-form form .form-group:nth-child(3) {
    margin-bottom: 20px;
  }

  .review-content .rating-breakdown .progress-bar .progress-box .au-progress {
    max-width: 250px;
  }

  .membership-content .trend:before {
    left: 67px;
  }
}
@media screen and (min-width: 1024px) {
  .container {
    max-width: 1200px;
  }
}
@media screen and (max-width: 1024px) {
  .hidden-tablet-landscape-up {
    display: block;
  }

  .header-home2 {
    position: relative;
  }

  .featured-content .item {
    display: block;
    text-align: center;
    margin-bottom: 30px;
  }

  .featured-content .item .item-thumb a {
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .featured-content .item .item-thumb {
    padding-right: 0px;
  }

  .featured-course-content .item .info .title {
    padding: 10px 20px 20px 20px;
  }

  .contact-info-two-content ul {
    padding: 0px;
  }

  /* .contact-info-two-content ul li {
    padding: 20px;
  } */
}
@media screen and (max-width: 992px) {
  .featured-content .featured-item:nth-child(2) .item,
  .featured-content .item {
    padding: 40px 10px;
  }

  .sign-up-content .sign-up-form {
    margin-top: 40px;
  }

  .testimonials-content .item .info {
    width: 100%;
  }

  #modal-video-01 .modal-dialog {
    max-width: 70%;
  }

  .featured-home2-content .item {
    margin-bottom: 30px;
  }

  .footer-info,
  .footer-menu,
  .lastest-news {
    margin-bottom: 40px;
  }

  .footer-title h4 {
    margin-bottom: 30px;
  }

  .footer-top-content .footer-info .footer-logo {
    margin-bottom: 5px;
  }

  /* .filter-featured-course li span {
    width: 130px;
  } */

  .featured-course-sidebar .featured-course-content {
    margin-bottom: 0px;
  }

  .featured-course-sidebar .featured-course-content {
    padding-right: 15px;
  }

  .single-course-content {
    padding-right: 15px;
  }

  .single-course-info figure img {
    width: 100%;
  }

  .review-content .rating-breakdown .progress-bar .progress-box .au-progress {
    max-width: 270px;
  }

  .aboutus-featured-content
    .aboutus-featured-info
    .featured-info-content
    .title,
  .aboutus-testimonials-content .item {
    padding: 0 100px;
  }

  .aboutus-featured-content .aboutus-featured-list .item {
    margin-bottom: 30px;
  }

  .aboutus-statistics .section-title .desc {
    padding: 25px 100px 22px;
  }

  .statistics-content .item {
    margin-bottom: 20px;
  }

  .membership-content .trend:before {
    left: 66px;
  }

  .single-events-content {
    padding-right: 0px;
  }

  .single-events-content
    .participants
    .participants-content
    .our-team-content
    .item {
    margin-bottom: 30px;
  }

  .blog-list .blog-content,
  .blog-page .blog-content,
  .single-content,
  .contact-us-content .contact-map {
    padding-right: 0px;
  }

  .single-content .comments,
  .contact-us-three-info {
    margin-bottom: 30px;
  }

  .contact-info-content .item {
    padding-top: 20px;
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 768px) {
  .item {
    margin-bottom: 30px;
  }

  #modal-video-01 .modal-dialog {
    max-width: 90%;
  }

  .our-gallery-content .item {
    width: 50%;
  }

  .filter-featured-course {
    display: block;
  }

  /* .filter-featured-course li span {
    width: 100%;
  }

  .filter-featured-course li {
    margin-right: 0px;
  } */

  .cta-content {
    display: block;
  }

  .cta-content .cta-title {
    margin-bottom: 30px;
  }

  .events-fqa-content {
    display: block;
  }

  .events-home3,
  .fqa {
    width: 100%;
  }

  .events-home3 {
    padding-right: 0px;
  }

  .slide-form {
    display: none;
  }

  .heading-page img {
    height: 200px;
  }

  .review-content {
    display: block;
  }

  .review-content .average-rating,
  .review-content .rating-breakdown {
    width: 100%;
  }

  .review-content .rating-breakdown .progress-bar .progress-box .au-progress {
    max-width: 80%;
  }

  .single-course-tab .nav-tabs .nav-link {
    padding: 23px 20px;
  }

  /* .single-course-tab .course-featured ul li {
    padding: 16px 20px 16px;
  } */

  .aboutus-featured-content
    .aboutus-featured-info
    .featured-info-content
    .title,
  .aboutus-testimonials-content .item {
    padding: 0 30px;
  }

  .aboutus-featured-content .aboutus-featured-info .featured-info-content {
    padding: 50px 0px;
  }

  .aboutus-statistics .section-title .desc {
    padding: 25px 0px 22px;
  }

  .contact-info-two-content ul {
    display: block;
  }

  /* .contact-info-two-content ul li {
    margin: 0 auto;
    margin-bottom: 30px;
  } */

  .contact-info-two-content ul li:last-child {
    margin-right: auto;
  }
}
@media screen and (max-width: 575px) {
  /* .statistics ul li {
    width: 50%;
    margin-bottom: 30px;
  } */

  .header-top-content {
    display: block;
    text-align: center;
  }

  .header-top-content .header-top-info {
    margin-bottom: 10px;
  }

  .single-course-content .single-course-info .course-teacher-cat,
  .single-course-tab .nav-tabs,
  .single-course-tab ul {
    display: block;
    text-align: center;
  }

  .single-course-content .single-course-info .course-teacher-cat .teacher-cat {
    margin-bottom: 30px;
  }

  .single-course-content
    .single-course-info
    .course-teacher-cat
    .teacher-cat
    ul {
    justify-content: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -o-justify-content: center;
    -ms-justify-content: center;
  }

  .single-course-tab .nav-tabs .nav-link:after {
    width: 0px;
  }

  /* .single-course-tab .course-featured ul li {
    margin-bottom: 0px;
    width: 50%;
    float: left;
  } */
  .single-course-tab .course-featured ul li:after {
    width: 0px;
  }

  .single-course-tab .course-overview {
    clear: both;
  }

  .single-course-tab .course-mission,
  .single-course-tab .course-outcome {
    width: 100%;
    float: none;
    margin-bottom: 40px;
  }

  .single-course-tab .course-mission {
    padding-right: 0px;
  }

  .single-course-tab .course-outcome {
    padding-left: 0px;
  }

  .instructor-content,
  .single-events-content .events-info ul,
  .single-content .comments .media .media-body .info {
    display: block;
  }

  /* .single-events-content .events-info ul li {
    margin-bottom: 20px;
  } */

  .instructor-content .instructor-thumb {
    width: 100%;
    margin-bottom: 30px;
  }

  .instructor-content .instructor-info {
    text-align: center;
  }

  .single-course-tab ul {
    margin: 0 auto;
  }
/* 
  .our-story-content ul li {
    width: 100%;
    float: none;
  } */

  .grid-sizer,
  .grid-item {
    width: 50%;
  }

  .single-content .comments .media .media-body .info .title-desc {
    width: auto;
    margin-bottom: 20px;
  }

  .testimonials-content .item figure img {
    height: 400px;
  }
}
@media screen and (max-width: 480px) {
  .video-tour-content .video .video-title {
    font-size: 35px;
  }

  .our-gallery-content .item {
    width: 100%;
  }

  .events-content-2 .item {
    display: block;
  }

  .events-content-2 .item .item-thumb {
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .events-content-2 .item figure {
    width: auto;
  }

  .sign-up-content .register-now .register-title {
    font-size: 45px;
  }

  /* .statistics-block ul li {
    float: none;
    padding-right: 0px;
    width: 100%;
    margin-bottom: 30px;
  } */

  /* .curriculum-content .card .card-body ul li .lesson-time,
  .curriculum-content .card .card-body ul li .finished {
    position: relative;
  } */

  .aboutus-testimonials-content .item .desc {
    font-size: 20px;
  }

  .aboutus-featured-content
    .aboutus-featured-info
    .featured-info-content
    .title {
    padding: 0px;
  }

  .filter-events,
  .filter-gallery {
    display: block;
    margin-bottom: 20px;
  }

  /* .filter-events li,
  .filter-gallery li {
    margin-bottom: 20px;
  } */

  .tags-socials {
    display: block;
  }
}
@media screen and (max-width: 400px) {
  .header-top-content .header-top-info a {
    margin-right: 0px;
    display: block;
    margin-bottom: 10px;
  }

  .about-image img {
    height: 150px;
  }

  .entry span {
    display: block;
  }

  /* .contact-info-two-content ul li {
    width: 290px;
    height: 250px;
  } */

  .header-mobile .header-top-mobile .logo {
    width: 50%;
  }

  .header-mobile .header-top-mobile .search-box form .search-icon {
    width: 50px;
    height: 50px;
  }

  .search-box {
    padding-left: 10px;
  }
}
