.student-registration-form {
    background-color: #f9f8ff;
    padding: 20px;
    border-radius: 10px;
    width: 50%;
    margin: 20px auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-title {
    text-align: center;
    color: #6a0dad;
    font-size: 24px;
    margin-bottom: 20px;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-label {
    color: #6a0dad;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-input, .form-select, .form-textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s ease-in-out;
}

.form-input:focus, .form-select:focus, .form-textarea:focus {
    border-color: #6a0dad;
}

.form-submit {
    padding: 10px 20px;
    background-color: #6a0dad;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.form-submit:hover {
    background-color: #551a8b;
}
