
.special-class-container {
    text-align: center;
    padding: 20px;
    background-color: #f0f8ff;
  }
  
  .special-class-heading {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .special-class-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  

  .class-item {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: left;
  }
  
  .class-name {
    font-size: 20px;
    color: #333;
    font-weight: bold;
  }
  
  .class-description {
    font-size: 14px;
    color: #666;
  }
  
  /* Media Queries for responsiveness */
  
  
  @media (max-width: 768px) {
    .special-class-heading {
      font-size: 24px;
      margin-bottom: 15px;
    }
  
    .class-item {
      max-width: 90%; 
      padding: 12px;
    }
  
    .class-name {
      font-size: 18px;
    }
  
    .class-description {
      font-size: 13px;
    }
  }
  

  @media (max-width: 480px) {
    .special-class-heading {
      font-size: 20px;
      margin-bottom: 10px;
    }
  
    .class-item {
      max-width: 100%;
      padding: 10px;
    }
  
    .class-name {
      font-size: 16px;
    }
  
    .class-description {
      font-size: 12px;
    }
  }
  