
.reports-for-teachers {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    color: #333;
    text-align: center;
  }
  
  .form {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    color: #555;
    width: 100%;
  }
  
  label select {
    margin-top: 5px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  

  .student-report {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #eee;
    margin-top: 20px;
  }
  
  .student-report h3 {
    color: #444;
  }
  
  .stars {
    color: #fbc02d;
  }
  
  .stars span {
    font-size: 20px;
  }
  

  
  /* Tablet */
  @media (min-width: 600px) and (max-width: 1024px) {
    .form {
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  
    label {
      width: 30%;
    }
  }
  
  /* Mobile */
  @media (max-width: 599px) {
    .form {
      flex-direction: column;
    }
  
    .reports-for-teachers {
      padding: 15px;
    }
  
    label {
      width: 100%;
    }
  
    .student-report {
      padding: 10px;
    }
  
    h2, h3 {
      font-size: 1.2em;
    }
  
    .stars span {
      font-size: 18px;
    }
  }
  