
.assignments-teacher-container {
    background-color: #ffffff;
    padding: 2rem;
    max-width: 600px;
    margin: 2rem auto;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: Arial, sans-serif;
  }
  

  .title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #d32f2f;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  

  .assignment-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-size: 1rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  select, input[type="number"], input[type="text"] {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
  }
  
  select:focus, input[type="number"]:focus, input[type="text"]:focus {
    border-color: #d32f2f;
    outline: none;
  }
  

  .submit-button {
    padding: 0.7rem;
    font-size: 1rem;
    font-weight: bold;
    background-color: #d32f2f;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #a02828;
  }
  