
.library-teachers-container {
    padding: 1.5rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: auto;
  }
  
  .book-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  input[type="text"],
  input[type="date"],
  button[type="submit"] {
    width: 100%;
  }
  
  .borrowed-books-table th,
  .borrowed-books-table td {
    padding: 0.75rem;
  }
  .buttonBook{
    background-color: crimson;
    color: whitesmoke;
    padding: 2px ;
    border-radius: 5px;
  }

  .buttonBook:hover{
    background-color: crimson;
    color: whitesmoke;
    padding: 2px ;
    border-radius: 5px;
  }
  
  /* Medium screens (tablets) */
  @media (max-width: 768px) {
    .library-teachers-container {
      padding: 1rem;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    h3 {
      font-size: 1.2rem;
    }
  
    .book-form {
      gap: 0.75rem;
    }
  
    .borrowed-books-table th,
    .borrowed-books-table td {
      padding: 0.5rem;
    }
  }
  
 
  @media (max-width: 480px) {
    .library-teachers-container {
      padding: 0.5rem;
    }
  
    h2 {
      font-size: 1.25rem;
      text-align: center;
    }
  
    h3 {
      font-size: 1rem;
      text-align: center;
      margin-bottom: 0.5rem;
    }
  
    .book-form {
      gap: 0.5rem;
    }
  
    .borrowed-books-table {
      font-size: 0.9rem;
      width: 100%;
      overflow-x: auto;
      display: block;
    }
  
    .borrowed-books-table th,
    .borrowed-books-table td {
      padding: 0.4rem;
      white-space: nowrap;
    }
  
   
    button[type="submit"] {
      font-size: 0.9rem;
      padding: 0.4rem 0.8rem;
    }
  }
  