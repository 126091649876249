
.sidebar {
  width: 250px;
  background-color:whitesmoke;
  color: rgb(63, 61, 61);
  padding: 1rem;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  height: 100vh;
  transition: all 0.3s ease-in-out;
}


.sidebar-title {
  color: #2d3748;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}


.sidebar ul {
  list-style: none;
  padding: 0;
}


.sidebar li {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  color: #2d3748;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.sidebar li:hover {
  background-color: #edf2f7;
}

.sidebar li .icon {
  margin-right: 8px;
  font-size: 1.1rem;
}


.sidebar .active {
  background-color: #e2e8f0;
  border-radius: 4px;
}

.sidebar .inactive {
  color: #718096;
}

/* Responsive Design for Medium Screens (Max-width: 1024px) */
@media (max-width: 1024px) {
  .sidebar {
      width: 80px;
      padding: 1rem 0.5rem;
  }

  .sidebar-title {
      display: none;
  }

  .sidebar li {
      justify-content: center;
      padding: 8px;
  }

  .sidebar li .sidebar-text {
      display: none;
  }

  .sidebar li .icon {
      margin-right: 0;
  }
}

/* Responsive Design for Small Screens (Max-width: 480px) */
@media (max-width: 480px) {
  .sidebar {
      width: 60px;
  }

  .sidebar li {
      font-size: 0.8rem;
      padding: 6px;
  }

  .sidebar li .sidebar-text {
      display: none;
  }

  .sidebar li .icon {
      margin-right: 0;
  }
}

/* Submenu Styling */
.sidebar .submenu {
  margin-left: 1.5rem;
  list-style: none;
}

.sidebar .submenu li {
  font-size: 0.9rem;
  padding-left: 1.2rem;
  color: #4a5568;
}

.sidebar .submenu li:hover {
  background-color: #edf2f7;
  border-radius: 4px;
}
