
.activities-container {
    padding: 1.5rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    /* max-width: 600px; */
    margin: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .activities-container h2 {
    font-size: 1.8rem;
    color: #333;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  

  .activity-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  

  .activity-card {
    padding: 1rem;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  }
  
  .activity-card h3 {
    font-size: 1.5rem;
    color: #007bff;
    margin-bottom: 0.5rem;
  }
  
  .activity-card p {
    margin: 0.2rem 0;
    font-size: 1rem;
    color: #555;
  }
  

  .activity-card.sports {
    border-left: 4px solid #28a745;
  }
  
  .activity-card.science {
    border-left: 4px solid #17a2b8;
  }
  
  .activity-card.exam {
    border-left: 4px solid #ffc107;
  }
  
  .activity-card.test {
    border-left: 4px solid #dc3545;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .activities-container {
      padding: 1rem;
    }
  
    .activity-card h3 {
      font-size: 1.4rem;
    }
  
    .activity-card p {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 480px) {
    .activities-container {
      padding: 0.8rem;
    }
  
    .activities-container h2 {
      font-size: 1.5rem;
    }
  
    .activity-card h3 {
      font-size: 1.3rem;
    }
  
    .activity-card p {
      font-size: 0.9rem;
    }
  }
  