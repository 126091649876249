
.fees-teacher-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f4f6f8;
  }
  

  .page-heading {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  

  .offline-fees-container {
    width: 100%;
  
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    text-align: center;
  }
  
  .offline-message {
    font-size: 18px;
    color: #555;
  }
  
 
  .online-fees-container {
    /* width: 100%; */
    /* max-width: 600px; */
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .documents-section,
  .fees-categories-section {
    margin-bottom: 20px;
  }
  
  h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .document-list {
    list-style: none;
    padding: 0;
    margin: 0;
    color: #555;
  }
  
  .document-list li {
    padding: 8px 0;
  }
  
  .fee-category {
    background-color: #fafafa;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    margin-bottom: 10px;
  }
  
  .fee-category h4 {
    margin: 0 0 5px;
    font-size: 18px;
  }
  
  .fee-category p {
    margin: 0;
    font-size: 16px;
    color: #666;
  }
  
  /* Media Queries for Responsive Design */
  @media (min-width: 768px) {
    .fees-teacher-container {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .offline-fees-container,
    .online-fees-container {
      width: 48%;
    }
  }
  
  @media (max-width: 600px) {
    .page-heading {
      font-size: 20px;
    }
  
    .offline-message,
    .fee-category p {
      font-size: 14px;
    }
  
    .document-list li,
    .fee-category h4 {
      font-size: 16px;
    }
  }
  