/* AccountTeachers.css */
.account-teachers-container {
    text-align: center;
    padding: 20px;
    background-color: #f7f9fc;
  }
  
  .account-teachers-heading {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .payment-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .payment-type {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .payment-title {
    font-size: 20px;
    font-weight: bold;
    color: #444;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .payment-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }
  
  .payment-table th,
  .payment-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .payment-table th {
    background-color: #f1f1f1;
    font-weight: bold;
    color: #555;
  }
  
  .payment-table td {
    color: #666;
  }
  

  
  /* For tablets and smaller screens */
  @media (max-width: 768px) {
    .account-teachers-heading {
      font-size: 24px;
    }
  
    .payment-type {
      padding: 12px;
    }
  
    .payment-title {
      font-size: 18px;
    }
  
    .payment-table th,
    .payment-table td {
      padding: 8px;
      font-size: 14px;
    }
  }
  
  /* For mobile devices */
  @media (max-width: 480px) {
    .account-teachers-heading {
      font-size: 20px;
    }
  
    .payment-table th,
    .payment-table td {
      padding: 6px;
      font-size: 12px;
    }
  }
  