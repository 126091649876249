.sidebar {
  width: 250px;
  background-color: white;
  color: rgb(63, 61, 61);
  padding: 1rem;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);

  height: auto;
  transition: all 0.3s ease-in-out;
}

.sidebar h2 {
  margin-bottom: 1.5rem;
  color: #2d3748;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  color: #2d3748;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.sidebar li .icon {
  margin-right: 8px;
}

.sidebar .active {
  background-color: #e2e8f0;
  border-radius: 4px;
}

.sidebar .inactive {
  color: #718096;
}

/* Media Queries for responsiveness */

/* For screens larger than 1024px */
@media (min-width: 1024px) {
  .sidebar {
    width: 250px;
  }
}

/* For tablets and medium screens */
@media (max-width: 1024px) {
  .sidebar {
    width: 200px;
  }

  .sidebar li {
    font-size: 0.9rem;
  }
}

/* For mobile screens (max-width 768px) */
@media (max-width: 768px) {
  .sidebar {
    width: 80px;
  }

  .sidebar h2 {
    display: none;
  }

  .sidebar li {
    justify-content: center;
    margin: 0.3rem 0;
  }

  .sidebar li span {
    display: none;
  }

  .sidebar li .icon {
    margin-right: 0;
  }
}

/* For very small screens (max-width 480px) */
@media (max-width: 480px) {
  .sidebar {
    width: 60px;
  }

  .sidebar li {
    font-size: 0.7rem;
  }

  .sidebar li span {
    display: none;
  }

  .sidebar li .icon {
    margin-right: 0;
  }
}
