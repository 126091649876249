
.attendance-teacher-container {
  padding: 1.5rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.self-attendance h2,
.student-attendance h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.self-attendance label,
.student-attendance label {
  display: block;
  margin: 0.5rem 0;
}

.self-attendance button,
.student-attendance button {
  padding: 0.5rem 1rem;
  background-color:crimson;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
}

.self-attendance button:hover,
.student-attendance button:hover {
  background-color: #357ABD;
}

.student-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.student-table th,
.student-table td {
  border: 1px solid #ddd;
  padding: 0.5rem;
  text-align: left;
}

.student-table th {
  background-color: #f3f4f6;
}

.buttonsubmit {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.buttonsubmit:hover {
  background-color: #218838;
}


.student-attendance-teacher {
  padding: 1.5rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.student-attendance-teacher h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.student-attendance-teacher label {
  display: block;
  margin: 0.5rem 0;
}

.student-attendance-teacher button {
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  cursor: pointer;
  background-color: #4a90e2;
  color: #fff;
  border: none;
  border-radius: 4px;
}

.student-attendance-teacher button:hover {
  background-color: #357ABD;
}

.student-attendance-teacher .student-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.student-attendance-teacher .student-table th,
.student-attendance-teacher .student-table td {
  border: 1px solid #ddd;
  padding: 0.5rem;
  text-align: left;
}

.student-attendance-teacher .student-table th {
  background-color: #f3f4f6;
}

.student-attendance-teacher .submit-button {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.student-attendance-teacher .submit-button:hover {
  background-color: #218838;
}


@media (max-width: 1024px) {
  .attendance-teacher-container,
  .student-attendance-teacher {
    padding: 1rem;
  }

  .student-table th,
  .student-table td,
  .student-attendance-teacher .student-table th,
  .student-attendance-teacher .student-table td {
    padding: 0.4rem;
  }
}

@media (max-width: 480px) {
  .attendance-teacher-container,
  .student-attendance-teacher {
    padding: 0.8rem;
  }

  .self-attendance h2,
  .student-attendance h2,
  .student-attendance-teacher h2 {
    font-size: 1.2rem;
  }

  .student-table th,
  .student-table td,
  .student-attendance-teacher .student-table th,
  .student-attendance-teacher .student-table td {
    font-size: 0.9rem;
  }

  .self-attendance button,
  .student-attendance button,
  .student-attendance-teacher button {
    padding: 0.4rem 0.8rem;
  }
}
